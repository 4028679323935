<template>
  <!-- 商户列表 -->
  <div class="wrap">
    <TablePage
      ref="tablePage"
      v-model="options"
      @handleEvent="handleEvent"
    ></TablePage>

    <!-- 密码重置 -->
    <resetPWDialog
      :openDialog.sync="openResetPWDialog"
      :form="PWForm"
      @handleResetPW="handleResetPW"
    />

    <!-- 回收站 -->
    <c-dialog title="回收站" :width="1000" :showDialog.sync="recycleBinDialog">
      <template #content>
        <el-table
          :data="recycleBinList"
          style="width: 100%"
          @selection-change="recycleBinSelectionChange"
          height="500"
          border
        >
          <el-table-column align="center" type="selection" width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="tenantNo"
            label="商户号"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="tenantName"
            label="商户名称"
            width="120"
          >
          </el-table-column>
          <el-table-column align="center" prop="userName" label="用户账号">
          </el-table-column>
          <el-table-column align="center" prop="nickName" label="用户昵称">
          </el-table-column>
          <el-table-column align="center" prop="employeeNo" label="员工编号">
          </el-table-column>
          <el-table-column align="center" prop="employeeName" label="员工姓名">
          </el-table-column>
          <el-table-column
            align="center"
            prop="telephone"
            label="手机号"
            width="120"
          >
          </el-table-column>
        </el-table>
        <TablePagination
          :page.sync="dialogQueryParams.pageNum"
          :limit.sync="dialogQueryParams.pageSize"
          :total="dialogTotal"
          @pagination="getList"
        ></TablePagination>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="closeRestoreFn">取 消</el-button>
        <el-button size="mini" type="primary" @click="restoreFn"
          >还 原</el-button
        >
      </template>
    </c-dialog>
  </div>
</template>

<script>
import { AllTenantAPI } from '@/api/system/tenant'
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  tenantEmployeeList,
  removeEmployee,
  updatePassword,
  changeEmployeeStatusAPI,
  restoreReduction
} from '@/api/tenant/base/employee'
import TablePage from '@/components/tablePage/index.vue'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import resetPWDialog from '@/views/tenant/components/resetPWDialog' //重置密码弹窗
import { changeTenantStatus } from '@/api/system/tenant'
import { getDicts } from '@/api/system/dict/data'

export default {
  name: 'TenantList',
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination,
    resetPWDialog,
    CDialog
  },
  dicts: ['tenant_service_state'],
  data () {
    return {
      options: {
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          userName: undefined, //账号
          employeeName: undefined, //姓名
          telephone: undefined, // 手机号
          status: undefined // 状态
        },
        getListApi: tenantEmployeeList,
        title: '商户员工',
        rowKey: 'employeeId',
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            clearable: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: '',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
           {
            type: 'local',
            label: '商户号/名称',
            labelWidth: '100',
            model: '',
            filter: 'tenantId',
            seniorSearch: true,
            option: {
              clearable: true,
              remote: AllTenantAPI,
              downLoad: 15,
              label: 'tenantName',
              value: 'tenantId',
              filterable: false,
              labels: [
                { title: '编号', label: 'tenantNo' },
                { title: '名称', label: 'tenantName' }
              ]
            }
          },
          {
            label: '登录名称',
            type: 'input',
            seniorSearch: true,
            tip: '请输入登录名称(账号)',
            model: '',
            filter: 'userName'
          },
          {
            label: '员工姓名',
            type: 'input',
            tip: '请输入员工姓名',
            seniorSearch: true,
            model: '',
            filter: 'employeeName'
          },
          {
            label: '手机号码',
            type: 'input',
            tip: '请输入手机号码',
            seniorSearch: true,
            model: '',
            filter: 'telephone'
          },
          {
            type: 'local',
            label: '状态',
            model: '',
            filter: 'status',
            seniorSearch: true,
            option: {
              remote: getDicts,
              remoteBody: 'tenant_service_state',
              label: 'dictLabel',
              value: 'dictValue'
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'start',
            label: '启用',
            alertText: '确认要启用选中员工吗？',
            btnType: 'dropdown',
            other: [
              {
                click: 'close',
                label: '禁用',
                alertText: '确认要禁用选中员工吗？'
              }
            ],
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'password',
            label: '密码重置',
            type: 'primary',
            disabled: () => this.options?.check?.length !== 1
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: () => !this.options?.check?.length,
            btnType: 'dropdown',
            trigger: "click", 
            other: [
               {
                    label: '回收站',
                    collectionDialog: {
                      restoreListApi: restoreReduction,
                      dcollectionBody: {
                        delFlag: '1'
                      }
                    }
                  }
            ]
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh-right',
            type: ''
          }
        ],
        columns: [
          {
            prop: 'tenantNo',
            type: 'link',
            click: 'link',
            label: '商户号',
            width: '200'
          },
          { prop: 'tenantName', label: '商户名称', width: '160' },
          {
            prop: 'status',
            label: '启用状态',
            width: '200',
            activeText (row) {
              return row.status == '1' ? '禁用' : row.status == '0'? '启用':'正常'
            }
          },
          { prop: 'userName', label: '用户账号', width: '160' },
          { prop: 'nickName', label: '用户昵称', width: '200' },
          { prop: 'employeeNo', label: '员工编号', width: '200' },
          { prop: 'employeeName', label: '员工姓名', width: '160' },
          { prop: 'email', label: '邮箱', width: '200' },
          {
            prop: 'sex',
            label: '性别',
            width: '160',
            activeText (row) {
              return row.status == '1'
                ? '女'
                : row.status == '0'
                ? '男'
                : '未知'
            }
          },
          { prop: 'telephone', label: '手机号', width: '160' },
          { prop: 'address', label: '地址', width: '200' },
          { prop: 'deptName', label: '所属部门', width: '200' },
          { prop: 'createBy', label: '创建人', width: '160' },
          { prop: 'createTime', label: '创建时间', width: '200' },
          { prop: 'auditBy', label: '审核人', width: '160' },
          { prop: 'auditTime', label: '审核时间', width: '160' }
        ],
        list: []
      },

      PWForm: {
        employeeId: ''
      },
      openResetPWDialog: false,

      recycleBinDialog: false,

      recycleBinList: [], // 回收站列表

      dialogQueryParams: {
        delFlag: 1,
        pageNum: 1,
        pageSize: 15
      },
      dialogTotal: 0,

      recycleBinSelect: []
    }
  },
  methods: {
    handleEvent (type, row) {
      switch (type) {
        case 'link':
          this.$router.push(
            `/tenant/base/info/handleTenantInfoDetail?type=edit&tenantId=${row.tenantId}`
          )
          break
        case 'password':
          if (this.options.check.length !== 1) {
            return this.$message.error(
              '请选择一个员工,密码重置对单个员工进行操作'
            )
          }
          this.PWForm.employeeId = this.options.check[0]
          this.openResetPWDialog = true
          break
        case 'start':
          this.isStatus('0')
          break
        case 'close':
          this.isStatus('1')
          break
        case 'del':
          if (this.options.check.length <= 0)
            return this.$message.error('请选择员工')
          const name = this.options.check.map(item => item.employeeName)
          const employeeIds = this.options.check.map(item => item.employeeId)
          this.$confirm(`确定删除${name.join(',')}员工`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              await removeEmployee(employeeIds)
              await this.$refs.tablePage.getList();
              this.$message.success('删除成功')
            })
            .catch(() => {})
          break
        case 'recycle':
          this.getList()
          break
      }
    },

    //操作重置密码
    async handleResetPW () {
      await updatePassword(this.PWForm)
      this.$message.success('重置密码成功')
      this.openResetPWDialog = false
      this.PWForm = {
        employeeId: undefined, //员工id
        oldPassword: undefined, //旧密码
        newPassword: undefined, //新密码
        confirmPW: undefined //确认密码
      }
    },

    // 关闭回收站弹框
    closeRestoreFn () {
      this.recycleBinDialog = false
    },

    // 还原
    restoreFn () {
      changeTenantStatus(this.recycleBinSelect, 0).then(res => {
        this.$message.success('还原成功')
        this.recycleBinDialog = false
      })
    },

    // 启用/禁用
    isStatus (status) {
      let text = status == '0' ? '启用' : '禁用'
      let  statusOld = status == '0' ? '1' : '0'
      const ids = this.options.check.filter(x=>x.status==statusOld).map(item => item.employeeId).filter(item=>item!=undefined)
      if (ids.length == 0)return this.$message.error(`请选择${text}状态的员工！`)
      // 继续处理启用/禁用逻辑
      changeEmployeeStatusAPI({
        employeeIds: ids,
        status: status
      }).then(res => {
        this.$refs.tablePage.getList()
        this.$message.success(`${text}成功`)
      })
    },

    getList () {
      tenantEmployeeList(this.dialogQueryParams).then(res => {
        const { rows, total } = res
        this.recycleBinList = rows
        this.dialogTotal = total
        this.loading = false
        this.recycleBinDialog = true
      })
    },

    recycleBinSelectionChange (val) {
      this.recycleBinSelect = val.map(x => x.tenantId)
    }
  }
}
</script>
